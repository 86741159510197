import React from 'react'

export const CopyLinkIcon: React.FunctionComponent = () => (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.6 3.99999C1.6 2.85999 2.52667 1.93332 3.66667 1.93332H6.33334V0.666656H3.66667C2.78261 0.666656 1.93477 1.01785 1.30965 1.64297C0.684525 2.26809 0.333336 3.11593 0.333336 3.99999C0.333336 4.88405 0.684525 5.73189 1.30965 6.35701C1.93477 6.98213 2.78261 7.33332 3.66667 7.33332H6.33334V6.06666H3.66667C2.52667 6.06666 1.6 5.13999 1.6 3.99999ZM4.33334 4.66666H9.66667V3.33332H4.33334V4.66666ZM10.3333 0.666656H7.66667V1.93332H10.3333C11.4733 1.93332 12.4 2.85999 12.4 3.99999C12.4 5.13999 11.4733 6.06666 10.3333 6.06666H7.66667V7.33332H10.3333C11.2174 7.33332 12.0652 6.98213 12.6904 6.35701C13.3155 5.73189 13.6667 4.88405 13.6667 3.99999C13.6667 3.11593 13.3155 2.26809 12.6904 1.64297C12.0652 1.01785 11.2174 0.666656 10.3333 0.666656Z"
            fill="#0B70DB"
        />
    </svg>
)
